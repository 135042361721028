import React from 'react';

import FadeIn from 'react-fade-in';

const About = ({property}) => {
  
    return (
      <FadeIn transitionDuration={1000}>
        <div className="container">   

          <div className="jumbotron">
            <p>
              Hello, my name is Theron and I'm a software developer with over 30 years in the field who has worked in a wide variety of domains.
              This site is a work-in progress "portfolio" which has been deployed to AWS.
              I want to thank you for taking the time to look at this.
              <br />
            </p>
          </div>
        
          <ul className="list-group ">
            <h2> Overview of domains familiar with on the job </h2>
            <li className = "list-group-item list-group-item-primary">Healthcare & Insurance</li>
            <li className = "list-group-item list-group-item-secondary">Grocery</li>
            <li className = "list-group-item list-group-item-success">Computer Telephony/Sales</li>
            <li className = "list-group-item list-group-item-light">Chemistry/Biology (Monsanto/Tripos)</li>
            <li className = "list-group-item list-group-item-danger">Retail</li>
            <li className = "list-group-item list-group-item-secondary">Travel</li>                   
            <li className = "list-group-item list-group-item-primary">Commissary</li>        
          </ul>            
              
          <ul className="list-group">
            <h2>Overview of technologies familiar with on the job or professionally</h2>
            <li className = "list-group-item list-group-item-primary">AWS</li>
            <li className = "list-group-item list-group-item-secondary">.NET/C#</li>
            <li className = "list-group-item list-group-item-success">SQL Server / Relational DB / SQL / Stored Procs</li>
            <li className = "list-group-item list-group-item-light" > ReactJS / Redux / Hooks, Angular2, AngularJS </li>
            <li className = "list-group-item list-group-item-danger">Javascript/HTML/CSS</li>
            <li className = "list-group-item list-group-item-primary">MongoDB</li>                   
            <li className = "list-group-item list-group-item-secondary">Okta/OAuth2</li>
            <li className = "list-group-item list-group-item-success">Git/Github</li>
            <li className = "list-group-item list-group-item-light">Jenkins</li>
          </ul>
                    
          <ul className="list-group">
            <h2>Technologies while volunteering to help an instructor enhance an online Udemy course with student enrollments</h2>
            <li className = "list-group-item list-group-item-primary">Docker/Kubernetes/Microservices</li>                            
          </ul>   
                    
          <ul className="list-group">
            <h2>Technologies off hours to pursue interests</h2>
            <li className = "list-group-item list-group-item-primary">Python/Machine Learning/Jupyter/Data Wrangling</li>                            
            <li className = "list-group-item list-group-item-secondary">Domain Driven Design/Microservices</li>                            
          </ul>          
                
          <div className="row">
            <strong>More to come</strong>
          </div>      
        
        </div>
      </FadeIn>
  );        
}

export default About;