import React from 'react';
import FadeIn from 'react-fade-in';

const MLBStudy = ({property}) => {
  return (
    <FadeIn transitionDuration={1000}>
      <div className="container">     
        <div className="jumbotron">
          <p>
            A side project I worked on for several months to learn Python/Jupyter, Data Wrangling, etc..
            I applied it to my love for major league baseball.
            For any potential task, <strong>I would be more than happy to join a video conference
            and share my screen to go over this application</strong>
          </p>
          <a href = "https://medium.com/baseball-data-wrangling/using-data-and-statistics-to-answer-unusual-baseball-questions-like-the-late-season-magical-runs-60e4988801cd"
            target = "blank">
            Post on Medium describing the Study
          </a>
          <br/>          
          <a href = "https://baseballfan999.wixsite.com/baseballdat/post/scenarios"
            target = "blank">
            Go straight to the study.
          </a>
          <br />
          <a href = "https://twitter.com/MLBOddStudies"
            target = "blank" >
            My twitter for that as I wanted to keep it seperate from my work - @MLBOddStudies
          </a>         
        </div>
        
        <ul className="list-group">
            <h2>Concepts/technologies used in this study</h2>
            <li className = "list-group-item list-group-item-primary">Data Wrangling / Statistics</li>
            <li className = "list-group-item list-group-item-secondary">Python</li>
            <li className = "list-group-item list-group-item-success">Python & Jupyter & Panda</li>
            <li className = "list-group-item list-group-item-danger">C#/.NET Core</li>
            <li className = "list-group-item list-group-item-light">MySQL</li>            
        </ul>
      </div>        
    </FadeIn>  
  );
}

export default MLBStudy;