import React from 'react';
import FadeIn from 'react-fade-in';

const SampleApp = ({property}) => {
  return (
    <FadeIn transitionDuration={1000}>
      <div className="container">     
        <div className="jumbotron">
          <p>
            An Angular todo App using Serverless Framework in AWS.
            This app uses the following Services in AWS.
            If need be and the circumstances were right, <strong>I would be more than happy to join a video conference
            and share my screen to go over this application</strong>
          </p>
          <a href="https://d2wxtwp7fytxgw.cloudfront.net" target="blank">
            Run the app (requires a google login)
          </a>
        </div>
        
        <ul className="list-group">
            <h2>AWS Services used in this app</h2>
            <li className = "list-group-item list-group-item-primary">Api Gateway</li>
            <li className = "list-group-item list-group-item-secondary"> AWS Cognito Identity Pool </li>
            <li className = "list-group-item list-group-item-success">Route 53</li>
            <li className = "list-group-item list-group-item-danger">CloudFront</li>
            <li className = "list-group-item list-group-item-light">Lambda functions</li>
            <li className = "list-group-item list-group-item-info">s3</li>
            <li className = "list-group-item list-group-item-warning">DynamoDB</li>          
            <li className = "list-group-item list-group-item-primary"> Code Commit </li>  
            <li className = "list-group-item list-group-item-secondary"> Code Deploy </li>  
            <li className = "list-group-item list-group-item-success" > Code Pipeline </li> 
        </ul>


      </div>        
    </FadeIn>  
  );
}

export default SampleApp;