import React, { useState } from 'react';
import './App.css';
import logo from './assets/logo.svg';
import About from './About';
import SampleApp from './SampleApp';
import MLBStudy from './MLBStudy';


function App() {
  const [currentScreen, setCurrentScreen] = useState(0);
  const red = {"background-color":"darkred"}
  const green = {
    "background-color": "darkgreen"
  }
  const blue = {
    "background-color": "darkblue"
  }
  const yellow = {
    "background-color": "grey"
  }
  let component = <About/>;

  if (currentScreen == 1) {
    component = <MLBStudy />;
  }
  else if (currentScreen == 2){
    component = <SampleApp />;
  }
  return (
    <div className="App">    
      <ul class="snip1226">  
        <li><a href="#" onClick={()=>setCurrentScreen(0)} data-hover="About">About</a></li>
        <li><a href="https://www.linkedin.com/in/theron-kousek-984bab/" target="_blank" 
          data-hover="Linked In">Linked In</a></li>
        <li><a href="http://tbsoftware.wordpress.com" target="_blank" 
            data-hover="Blog">Blog</a></li> 
        <li><a href="#" onClick={()=>setCurrentScreen(1)}
          data-hover="Python Study - MLB">Python Study - MLB</a></li>         
        <li> <a href = "#"
          onClick = {() => setCurrentScreen(2)}
          data-hover="Sample AWS App">Sample AWS App</a></li>                                    
      </ul>    
    
      <section>
          <img src={logo} className="App-logo" alt="logo" style={red}/>
          <img src={logo} className="App-logo" alt="logo" style={green} />
          <img src={logo} className="App-logo" alt="logo" style={blue} />
          <img src={logo} className="App-logo" alt="logo" style={yellow} />
          
          <h1 className="myName">Theron Kousek </h1>
          <h2>St. Louis, Missouri</h2>
          <p className="copywrite">&copy; 2020 - www.tbkousek.com</p>
      </section>   

      <div class="content">
        {             
          component
        } 
      </div>                                             
         
    </div>    
           
  ); 
}

export default App;
